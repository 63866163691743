import React, { Component } from "react";
import styled from "styled-components";

import { CenteredHeader } from "./App.js";
import { ColorScheme } from "../theme/styleConstants";

export const PortButton = styled.a`
  padding: 10px 15px;

  background: 0 0;
  border: 2px solid ${ColorScheme.secondary};
  border-radius: 3px;
  margin: 0 5px;
  color: ${ColorScheme.secondary};
  font-family: Gotham, Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
  &:hover {
    background: ${ColorScheme.primary};
    color: #fff;
    border-color: ${ColorScheme.primary};
  }

  @media (max-width: 600px) {
    padding: 5px 7px;
  }
`;
const PortTitle = styled.h4`
  flex: 1;
  font-family: Gotham, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  alight-items: center;
  max-width: 1100px;
  @media (max-width: 300px) {
    text-align: center;
  }
`;

const BoltSmall = styled.small`
  font-weight: 700;
`;

const NotItelian = styled.span`
  font-style: normal;
  font-weight: 700;
`;

class Experience extends Component {
  render() {
    return (
      <div>
        <PortTitle>
          <span>Software Developer</span> <small>August 2022 - July 2023</small>{" "}
        </PortTitle>

        <p style={{ "font-size": "15px" }}>
          <i>
            Acadamic Work- Consultant At ABB Oy .{" "}
            <BoltSmall>Helsinki</BoltSmall>
          </i>
          <h6>
            Worked on ABB Ability™ Mobile Connect for Drives and an R&D project
            focused on web views. Developed cross-platform features with secure
            code and unit tests, and created views for system updates from IoT
            devices.
          </h6>
          <h6>
            <i>
              <strong>Tech stack:</strong> Typescript, JavaScript, Vue, Vuex,
              Ionic, Pug, CSS, Azure DevOps, ASP.NET.
            </i>
          </h6>
          <h6>
            <ul type="square">
              <li>
                Set up complete application localization translation and
                developed internal features for the R&D project.
              </li>

              <li>
                Collaborated closely with Product owners, Project managers and
                Architect.
              </li>
              <li>Received Kudos awards for contributions to the projects.</li>
              <li>
                Wrote technical documents and supported team members with issue
                resolution.
              </li>
              <li>
                Solved git issues for developers blocked by the company network.
              </li>
              <li>Worked on message handling, APIs, and Azure DevOps.</li>
            </ul>
          </h6>
        </p>
        <br />
        <PortTitle>
          <span>Associate Developer, Remote (Part-time)</span>{" "}
          <small>March 2020 - January 2022</small>
        </PortTitle>
        <p style={{ "font-size": "15px" }}>
          <i>I-plex Inc. {" "}
          <BoltSmall> Dhaka</BoltSmall>
          </i>
          <h6>
            Worked remotely from Kokkola, Finland, for I-plex Inc, based in
            Dhaka, on a food quality check application that displayed the
            percentage of good vs. spoiled food, enabling businesses to monitor
            food freshness efficiently.
          </h6>
          <h6>
            <i>
              <strong>Tech stack:</strong> JavaScript, React JS, Redux, HTML,
              CSS, ExpressJS, NodeJS, MongoDB.
            </i>
          </h6>
          <h6>
            <ul type="square">
              <li>
                Developed scalable APIs which are consumed by other teams
                internally and third parties with ExpressJS.
              </li>
              <li>
                Developed customer reset password feature with OTP message
                system with Express JS, React and Redux.
              </li>
              <li>
                Worked closely with the Tech Leader, implemented B2B new
                features.
              </li>
            </ul>
          </h6>
        </p>
        <br />
        <PortTitle>
          <span>Database Engineer Trainee</span>{" "}
          <small>August 2016 - February 2017</small>
        </PortTitle>
        <p style={{ "font-size": "15px" }}>
          <i>New Technology System LTD (NTS). {" "}
          <BoltSmall> Chittagong </BoltSmall>
          </i>
          <h6>
            Worked on ERP implementation for Inventory Management Systems,
            focusing on database development and system configuration to meet
            business requirements.
          </h6>
          <h6>
            <i>
              <strong>Tech stack:</strong> JavaScript, React JS, Redux, HTML,
              CSS, ExpressJS, NodeJS, MongoDB.
            </i>
          </h6>
          <h6>
            <ul type="square">
              <li>
                Major responsibilities are in ERP Implementation of Inventory
                Management Systems.
              </li>
              <li>
                Implementation includes Configuration, preparation of functional
                & technical specification for data Interface, set up
                configuration.
              </li>
              <li>
                Worked in the area of Oracle Database Development based
                applications using Oracle SQL, PL/SQL, Forms, and
                Reports Developer.
              </li>
            </ul>
          </h6>
        </p>
      </div>
    );
  }
}

export default Experience;
