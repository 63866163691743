import React, { Component } from "react";

import { CenteredHeader } from "./App.js";

class Home extends Component {
  render() {
    return (
      <div>
        <CenteredHeader>
          Hey👋! I'm Alamin a full-stack developer based in Helsinki.
        </CenteredHeader>
        <p>
          <strong>
            With a passion for creating scalable, cloud-based applications and have gained experience working with
            ABB, Iplex, and NTS, as well as through freelance app development. I have a strong foundation in both
            front-end and back-end technologies and enjoy working in agile teams
            to solve real-world problems. Beyond work, I enjoy learning new
            things 📚, building personal projects 🔨, doing photography 📸,
            contributing to Open Source 🖥, and watching science fiction films 🎞.
          </strong>
        </p>
      </div>
    );
  }
}

export default Home;
